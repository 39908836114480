var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sub-page',{attrs:{"title":_vm.$t('NewReplies'),"subtitle":_vm.$t('Evaluation'),"root":_vm.$store.state.courses.course.name,"root-location":_vm.COURSE_NAVIGATION_RETURN_LINK(_vm.$store.state.courses.course),"icon":"mdi-alert-decagram","side-navigation":_vm.EVALUATION_NAVIGATION,"navigation-route":"evaluation","filters":_vm.filtersItems,"no-content-condition":_vm.items.length === 0,"no-content-text":"NoNewRepliesText","loading":_vm.loading,"fill-height":""},on:{"resetFilter":function($event){_vm.$store.state.settings.filtersData = {}}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('ws-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.headers,"items":_vm.itemsFiltered,"search":_vm.$store.state.settings.filtersData.search,"row-action":_vm.displayStudentReply},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-no-wrap",style:(("color : " + _vm.wsACCENT))},[_vm._v(_vm._s(item.name))])]}},{key:"item.task_name",fn:function(ref){
var item = ref.item;
return [_c('h5',[_vm._v(_vm._s(item.task_name))])]}},{key:"item.course_name",fn:function(ref){
var item = ref.item;
return [_c('h5',[_vm._v(_vm._s(item.course_name))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-no-wrap"},[_c('v-icon',{attrs:{"color":_vm.wsACCENT}},[_vm._v("mdi-calendar")]),_vm._v(" "+_vm._s(_vm.PARSE_TIME_STAMP(item.date , true ))+" ")],1)]}}])})]},proxy:true},{key:"dialog",fn:function(){return [_c('student-reply',{attrs:{"student-id":_vm.selectedStudentId,"component-id":_vm.selectedComponentId,"all-evaluated":_vm.items.length === 0,"next-student":_vm.nextStudentName,"next-component":_vm.nextComponentName,"display-next":""},on:{"success":_vm.checkCompletion,"next":_vm.displayNextStudent},model:{value:(_vm.displayReplyDialog),callback:function ($$v) {_vm.displayReplyDialog=$$v},expression:"displayReplyDialog"}})]},proxy:true}]),model:{value:(_vm.$store.state.settings.pageData),callback:function ($$v) {_vm.$set(_vm.$store.state.settings, "pageData", $$v)},expression:"$store.state.settings.pageData"}})}
var staticRenderFns = []

export { render, staticRenderFns }