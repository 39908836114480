<template>
  <v-dialog :overlay-color="wsACCENT" :transition="false" no-click-animation  v-model="display" style="position:relative">
    <v-sheet :color="loading ? wsLIGHTCARD : 'white'" class="student-reply fill-height" elevation="3">
      <v-row class="no-gutters flex-wrap flex-column fill-height ">

        <!-- Header -->
        <v-col cols="auto" class="shrink" >

          <template v-if="!loading">
            <!-- Student name and Close button -->
            <v-sheet color="transparent" class="px-6 py-3" >
              <div class="d-flex justify-space-between align-center">

                <div class="d-flex align-center">
                  <v-icon :color="wsACCENT" large class="mr-2">mdi-forum</v-icon>
                  <div>
                    <h4> {{ student.name }}</h4>
                    <h5> {{ component.name }}</h5>
                  </div>
                </div>


                <v-btn :color="wsACCENT" @click="display = false" icon> <v-icon>mdi-close</v-icon></v-btn>
              </div>
            </v-sheet>
            <v-divider  class="pb-3" :style="`border-color : ${wsBACKGROUND}`" />
            <!-- Student Result -->
            <v-sheet v-if="!evaluationSuccess" :style="`border : 1px solid ${wsDARKLIGHT}`"  :color="wsLIGHTCARD" class="px-6 py-3 mt-3 mb-6 mx-6 wsRoundedLight">
              <div class="d-flex justify-space-between align-center ">
                <h5 :style="`color : ${wsACCENT}`" >{{ $t('Result') }}:</h5>
                <ft-select
                    v-if="!component.is_evaluated"
                    @input="changeEvaluation($event, component , 'result_passed')"
                    :items="passedSelect"
                    :value="component.result_passed"
                    fill-height
                >
                  <h5 :style="`color : ${component.result_passed ? wsACCENT : wsDARKLIGHT}`"
                      class="pl-3"
                  >
                    {{ component.result_passed ? $t('Passed') : $t('NoResult')  }}
                  </h5>
                  <v-icon
                      :color="component.result_passed ? wsACCENT : wsDARKLIGHT"
                      class="mr-4"
                      small
                  >
                    mdi-menu-down
                  </v-icon>
                </ft-select>
                <ws-text-field
                    v-else
                    width="120"
                    @change="changeEvaluation($event, component , 'result_result')"
                    :value="component.result_result"
                    number
                    number-length="3"
                    :placeholder="component.pass_points || 50"
                    clearable
                />
              </div>
            </v-sheet>
          </template>

        </v-col>

        <!-- Content -->
        <v-col cols="auto" class="grow overflow-y-auto px-6 pt-3 pb-6" style="height: 100px"  >
          <template v-if="!loading">
          <template v-if="!evaluationSuccess">
            <div v-for="(reply, i) in replies" :key="i">
              <div class="d-flex justify-space-between align-center">
                <div class="d-flex align-center text-no-wrap">
                  <v-avatar class="mr-3"  :color="wsBACKGROUND"
                            size="40" key="profile_picture" >
                    <v-img v-if="reply.img" :src="reply.img"  transition="xxx">
                      <template slot="placeholder">
                        <v-img src="@/assets/placeholder/user_blank.jpg"  transition="xxx" />
                      </template>
                    </v-img>
                    <v-icon  dark v-else>mdi-account-tie</v-icon>
                  </v-avatar>
                  <div>
                    <h5 :style="`color : ${wsACCENT}`">{{ reply.name || (reply.is_teacher ? $t('TeacherReply') : '') }}    </h5>
                    <h6 class="mt-1 grey--text"> {{ PARSE_TIME_STAMP(reply.date)}}</h6>
                  </div>
                </div>
                <v-btn
                    v-if="reply.user_id === $store.state.auth.user.id"
                    @click="openDeleteReply(reply)"
                    icon
                >
                  <v-icon :color="wsACCENT">mdi-delete-outline</v-icon>
                </v-btn>
              </div>

              <ws-text-viewer
                  :value="reply.content"
                  allow-download-image
                  class="mt-3"
              />
            </div>
          </template>
          <v-sheet
              v-else
              :color="wsLIGHTCARD"
              :style="`border : 1px solid ${wsBACKGROUND}`"
              class="wsRoundedLight fill-height d-flex align-center justify-center pa-6">
            <div>
              <div class="d-flex justify-center">
                <v-icon size="80" class="mt-6 mx-auto" :color="wsSUCCESS" >mdi-check-circle-outline</v-icon>
              </div>

              <h5 v-if="!allEvaluated" class="mt-6 text-center">{{ $t('NextEvaluation') }}:</h5>

              <h3 :style="`color : ${wsACCENT}`" class="text-center mt-5"> {{ !allEvaluated ?  nextStudent : $t('NoNewRepliesText') }}</h3>
              <h4  v-if="!allEvaluated" class="text-center"> {{ nextComponent }}</h4>
              <div class="d-flex justify-center mt-5">
                <v-btn  v-if="!allEvaluated" @click="$emit('next'); evaluationSuccess=false" dark  :color="wsATTENTION" class="noCaps mx-auto px-9 " >
                  {{ $t('Next') }}
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
                <v-btn  v-else @click="display = false" dark  :color="wsATTENTION" class="noCaps mx-auto px-9 " >
                  <v-icon>mdi-close</v-icon>
                  {{ $t('Close') }}
                </v-btn>
              </div>

            </div>

          </v-sheet>
          </template>

          <div v-else class="fill-height d-flex align-center justify-center">
            <v-progress-circular indeterminate :color="wsACCENT" />
          </div>


        </v-col>

        <!-- Chats Footer -->
        <v-col  cols="auto" class="shrink" >
          <v-sheet v-if="!evaluationSuccess" class="pa-6" :color="wsLIGHTCARD">
            <v-sheet
                :style="`border: 1px solid ${wsDARKLIGHT}`"
                style="position: relative"
                class="pa-3 overflow-y-auto wsRoundedLight"
                max-height="300"
            >
              <ws-text-editor
                  v-model="replyData.content"
                  class="mb-16"
              />

              <div style="position: fixed; z-index: 999; bottom: 35px; right: 35px " class="d-flex justify-end ">
                <v-btn  @click="sendTeacherReply(studentId)" dark class="noCaps" elevation="0"  :color="wsATTENTION">
                  {{ $t('Send') }}
                </v-btn>
              </div>

            </v-sheet>

          </v-sheet>
        </v-col>

      </v-row>

      <ws-dialog
          v-if="displayDeleteReply"
          v-model="displayDeleteReply"
          @delete="deleteReply"
          :title="$t('DeleteReply')"
          :confirm-delete-text="$t('DeleteReplyText')"
          display-confirm-delete
          close-delete
          portal
      />

    </v-sheet>
  </v-dialog>

</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "studentReply",
  props : {
    value : {
      type : Boolean,
      default : false
    },
    studentId : {
      type : String,
    },
    componentId : {
      type : String
    },
    allEvaluated : {
      type : Boolean,
      default: false
    },
    nextStudent : {
      type : String
    },
    nextComponent : {
      type : String
    },
    displayNext : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      loading : false,
      display : false,
      replyData : { content : [] },
      replies : [],
      component : {},
      student : {},
      error : false,
      evaluationSuccess : false,
      displayDeleteReply : false,
      selectedReply : null,
    }
  },
  computed : {
    passedSelect() {
      return [
        { text : this.$t('Passed') , value : true },
        { text : this.$t('NoResult') , value : false }
      ]
    },
    hasReply() {
      if ( this.replies.length === 0 ) {
        return false
      }

      return this.replies.filter(el => !el.is_teacher).length > 0
    }
  },
  watch : {
    componentId() {
      this.initPage()
    },
    studentId() {
      this.initPage()
    },
    display() {
      if ( this.display !== this.value ) {
        this.$emit('input' , this.display)
      }
      if ( this.display ) {
        this.initPage()
      }
    },
    value() {
      if ( this.display !== this.value ) {
        this.display = this.value
      }
    }
  },
  methods : {
    ...mapActions('evaluation',[
        'EDIT_COMPONENT_EVALUATION' ,
        'RESET_STUDENT_REPLY' ,
        'ADD_EDIT_TEACHER_REPLY',
        'GET_TASK_REPLIES',
        'DELETE_TEACHER_REPLY'
    ]),

    openDeleteReply(reply) {
      this.selectedReply = reply.uuid
      this.displayDeleteReply = true
    },
    deleteReply() {
      let result = this.DELETE_TEACHER_REPLY(this.selectedReply)
      if ( !result ) {
        return
      }
      let index = this.replies.findIndex(el => el.uuid === this.selectedReply )
      if ( index === -1 ) {
        return
      }
      this.replies.splice(index , 1)
      this.displayDeleteReply = false
    },

    async changeEvaluation(value, item, field) {
      if ( field === 'result_result' ) {
        if ( !value ) {
          value = 0
        }
        if (value < 0) {
          value = 0
        }
        if (value > 100 ) {
          value = 100
        }
      }
      let data = {
        uuid : this.componentId,
        student_id : this.studentId,
        [field] : value
      }
      if ( item.result_id ) {
        data.result_id = item.result_id
      }
      let result = await this.EDIT_COMPONENT_EVALUATION(data)
      if ( !result ) { return }

      if ( !item.result_passed && result.passed && this.hasReply ) {
        this.$store.state.courses.courseNewRepliesCounter--
      }

      if ( !item.result_id ) {
        item.result_id = result.uuid
        item.result_passed = result.passed
      }
      item[field] = value
      item.result_passed = result.passed
      if ( result.passed ) {
        this.$emit('success')
        if ( this.displayNext ) {
          this.evaluationSuccess = true
        }

      }

      this.notify(this.$t('ChangesSaved'))
    },

    async sendTeacherReply() {

      if (!this.replyData.content) {
        return this.notify(this.$t('PleaseEnterAnswer'))
      }
      if (this.replyData.content.length === 0) {
        return this.notify(this.$t('PleaseEnterAnswer'))
      }

      let data = {
        component_id : this.componentId,
        student_id : this.studentId,
        content : this.replyData.content
      }
      let result = await this.ADD_EDIT_TEACHER_REPLY(data)
      if ( !result ) {
        return
      }

      this.replies.push(result)
      this.replyData.content = []
      this.notify(this.$t('TeacherReplyPublished') , 'success')
    },
    async resetStudentReply(item) {

      let result = await this.RESET_STUDENT_REPLY(item.task_reply_id)
      if ( !result ) {
        return
      }

      this.student = Object.assign({} , this.student)


    },

    async initPage() {
      this.loading = true
      this.evaluationSuccess = false
      let data = {
        component_id : this.componentId,
        student_id : this.studentId,
      }
      let result = await this.GET_TASK_REPLIES(data)
      this.loading = false
      if ( !result ) {
        this.error = true
        return
      }
      this.component = result.component
      this.replies = result.replies
      this.student = result.student_profile
    }
  },
  mounted() {
    this.display = this.value
  }
}
</script>

<style scoped>
.student-reply {
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  width: 600px;
}
</style>