<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('NewReplies')"
      :subtitle="$t('Evaluation')"
      :root="$store.state.courses.course.name"
      :root-location="COURSE_NAVIGATION_RETURN_LINK($store.state.courses.course)"
      icon="mdi-alert-decagram"
      :side-navigation="EVALUATION_NAVIGATION"
      navigation-route="evaluation"

      @resetFilter="$store.state.settings.filtersData = {}"

      :filters="filtersItems"

      :no-content-condition="items.length === 0"
      no-content-text="NoNewRepliesText"
      :loading="loading"

      fill-height
  >

    <template #default>

      <ws-data-table
          :headers="headers"
          :items="itemsFiltered"
          :search="$store.state.settings.filtersData.search"
          class="mt-5"
          :row-action="displayStudentReply"
      >

        <template #item.name="{item}">
          <h5 :style="`color : ${wsACCENT}`" class="text-no-wrap" >{{ item.name }}</h5>
        </template>

        <template #item.task_name="{item}">
          <h5>{{ item.task_name }}</h5>
        </template>

        <template #item.course_name="{item}">
          <h5>{{ item.course_name }}</h5>
        </template>

        <template #item.date="{item}">
          <h5 class="text-no-wrap">
            <v-icon :color="wsACCENT">mdi-calendar</v-icon>
            {{ PARSE_TIME_STAMP(item.date , true ) }}
          </h5>
        </template>

      </ws-data-table>
    </template>

    <template #dialog>
      <student-reply
          v-model="displayReplyDialog"
          :student-id="selectedStudentId"
          :component-id="selectedComponentId"
          @success="checkCompletion"
          :all-evaluated="items.length === 0"
          @next="displayNextStudent"
          :next-student="nextStudentName"
          :next-component="nextComponentName"
          display-next
      />
    </template>

  </sub-page>
</template>

<script>
import studentReply from "@/components/pages/westudy/admin/course/UI/studentReply";
import {mapActions} from "vuex";

export default {
  name: "evaluationNotifications",
  components : {
    studentReply
  },
  props : {
    uuid : {
      type : String
    }
  },
  data() {
    return {
      loading : false,
      items : [],
      displayReplyDialog : false,
      selectedStudentId : null,
      selectedComponentId : null,
      nextStudentIndex : -1,
    }
  },
  computed : {
    itemsFiltered() {
      let items = this.items
      if ( items.length === 0 ) {
        return []
      }
      let filters = this.$store.state.settings.filtersData
      if ( filters.component_id ) {
        items = items.filter(el => el.component_id === filters.component_id )
      }
      return items
    },
    tasksSelect() {
      let items = []
      if ( this.items.length === 0 ) {
        return items
      }

      let unique = [...new Map(this.items.map(el =>  [el.component_id, el]    )).values(), ]
      if (unique.length === 0) {
        return items
      }

      return unique.map(el => { return { value : el.component_id , text : el.task_name} })

    },
    filtersItems() {
      if ( this.tasksSelect.length === 0 ) {
        return []
      }
      return [
          { text: this.$t('Task'),
            value: 'component_id',
            type: 'select', items: this.tasksSelect
          }
      ]

    },
    headers() {
      return [

        { text : this.$t('Student') , value : 'name' , width: 10 },
        { text : this.$t('Task')    , value : 'task_name'    },
        { text : this.$t('Course_')    , value : 'course_name'    },
        { text : this.$t('Date') , value : 'date' , width : 10 },
      ]
    },
    nextStudentName() {
      if ( this.nextIndex === -1 ) {
        return null
      }
      if ( this.nextIndex + 1 > this.items.length ) {
        return null
      }
      return this.items[this.nextIndex].name
    },
    nextComponentName() {
      if ( this.nextIndex === -1 ) {
        return null
      }
      if ( this.nextIndex + 1 > this.items.length ) {
        return null
      }
      return this.items[this.nextIndex].task_name
    },
    nextIndex() {
      let index = -1
      if ( this.items.length === 0 ) {
        return -1
      }
      if ( this.nextStudentIndex === null ) {
        return 0
      }
      index = this.nextStudentIndex
      if ( this.nextStudentIndex === this.items.length ) {
        return index - 1
      }

      return index
    }
  },
  methods : {
    ...mapActions('evaluation',["GET_STUDENT_NEW_REPLIES_LIST"]),

    checkCompletion() {
      let index = this.items.findIndex(el => el.component_id === this.selectedComponentId && el.student_id === this.selectedStudentId)
      if ( index === -1 ) {
        return
      }
      this.nextStudentIndex = index
      this.items.splice(index, 1)
      this.$store.state.courses.courseNewRepliesCounter = this.items.length
    },
    displayStudentReply(item) {
      this.selectedStudentId   = item.student_id
      this.selectedComponentId = item.component_id
      this.displayReplyDialog  = true
    },
    displayNextStudent() {

      if ( this.items.length === 0 ) {
        return
      }

      if ( this.nextStudentIndex === this.items.length ) {
        this.nextStudentIndex--
      }

     this.displayStudentReply(this.items[this.nextStudentIndex])

    },

    async initPage() {
      this.$store.state.settings.filtersData = { component_id : null}
      this.loading = true
      let result = await this.GET_STUDENT_NEW_REPLIES_LIST(this.uuid)
      this.loading = false
      if ( !result || result === true ) {
        return
      }
      this.loading = false
      this.items = result
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>